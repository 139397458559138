//------------------------------------
// #THEME
//------------------------------------

// Global
$base-line-height: 24px;

$font-size-alpha:   40px;
$font-size-beta:    30px;
$font-size-gamma:   24px;
$font-size-epsilon: 16px;
$font-size-zeta:    14px;
$font-size-theta:   12px;

$heading-size-alpha:   (size: $font-size-alpha, line-height: auto);
$heading-size-beta:    (size: $font-size-beta, line-height: auto);
$heading-size-gamma:   (size: $font-size-gamma, line-height: auto);
$heading-size-epsilon: (size: $font-size-epsilon, line-height: auto);
$heading-size-zeta:    (size: $font-size-zeta, line-height: auto);

$is-heading-uppercase: false;

$base-spacing-unit:       $base-line-height;
$base-spacing-unit-small: round($base-spacing-unit / 2);
$base-spacing-unit-large: $base-spacing-unit * 2;

$mq-breakpoints: (
    mobile:  320px,
    tablet:  740px,
    desktop: 990px,
    wide:    1228px
);

$z-layers: (
    'quick-search':         9999,
    'summary-overlay':      9999,
    'topmenu':              9999,
    'body-loading-mask':    9999,
    'tooltip':              9999,
    'tooltips':             9999,
    'checkout-signin':      9999,
    'sticky-facet':         9999,
    'react-plp-sidebar':    9999,
    'react-suggestions':    9999,
    'modal':                5000,
    'modal-inner-wrap':     1001,
    'modal-overlay':        1000,
    'header-dropdown':      1000,
    'minicart':              999,
    'dropdown-open':         998,
    'dropdown':              995,
    'checkout-summary':      500,
    'loading-mask':          100,
    'loading-mask-image':    100,
    'sticky-header':         100,
    'product-badge':          10,
    'mobile-menu-header':      2,
    'mobile-menu-links':       1,
    'progress-item':           1,
    'progress-bar':            0,
    'checkbox-input':         -1,
    'radio-input':            -1,
    'bottomless-pit':     -10000
);

// Additional
$color-cyan:        #8AC0C4 !default;
$color-off-white:   #F4F2E9 !default;
$color-mono-b:      #D7D7D7 !default;
$color-mono-c:      #F4F4F4 !default;
$color-mono-d:      #898989 !default;
$color-transparent: transparent !default;

// Overrides
$color-blue:   #4A5052;
$color-yellow: #EEC218;
$color-red:    #EA4F34;
$color-green:  #0CA601;
$color-black:  #383D3F;

// UI
$color-ui-grey-2: #7B7C79;
$color-ui-grey-4: #C4C4C3;

// Brand
$color-brand-primary:   $color-red;
$color-brand-secondary: $color-cyan;

// Alerts
$color-alert-error:   $color-red;
$color-alert-success: $color-green;
$color-alert-warning: $color-yellow;

// Global
$color-font:   $color-ui-grey-2;
$color-body:   $color-ui-grey-2;
$color-border: $color-ui-grey-4;

// Links
$color-links:         $color-brand-secondary;
$color-links-active:  $color-brand-secondary;
$color-links-visited: $color-brand-secondary;

// Header
$color-default-header: $color-black;
$color-header-links:   $color-black;

// Footer
$color-footer-bg:                    $color-ui-grey-4;
$color-footer-copyright-bg:          $color-blue;
$color-footer-copyright-bg-mobile:   $color-blue;
$color-footer-headers:               $color-default-header;
$color-footer-list-header:           $color-footer-headers;
$color-footer-list:                  $color-footer-list-header;
$color-footer-copyright-text-mobile: $color-body;

// Nav
$color-nav-hover:           $color-brand-secondary;
$color-category-item:       $color-nav-hover;
$color-category-item-hover: $color-links;
$color-main-nav-bg:         $color-blue;

// Forms
$color-validation-error:       $color-alert-error;
$color-checkbox-border:        $color-ui-grey-4;
$color-checkbox-checked-bg:    $color-brand-secondary;
$color-input-border:           $color-ui-grey-4;
$color-input-border-focus:     $color-brand-primary;
$color-input-placeholder:      $color-ui-grey-2;
$color-input-focus:            $color-black;
$color-input-validation-valid: $color-brand-primary;
$color-input-validation-error: $color-alert-error;
$color-input-disabled:         $color-ui-grey-4;
$color-input:                  $color-ui-grey-2;
$color-label:                  $color-black;

// Buttons
$color-btn-bg:           $color-brand-secondary;
$color-btn-secondary-bg: $color-brand-primary;
$color-btn-plain:        $color-brand-primary;

// Tabs
$color-tab:          $color-black;
$color-tab-selected: $color-black;

// Ratings
$color-star-selected: $color-brand-secondary;

// Price
$color-price-special: $color-red;

// Theme
// Checkout
$color-checkout-sidebar-bg: $color-off-white !default;

// Nav
$color-main-nav-bg-hover: $color-black !default;

// Input
$color-input-focus:            $color-brand-secondary !default;
$color-input-validation-valid: $color-brand-secondary !default;

// Radio
$color-radio-background:                $color-off-white !default;
$color-radio-active-background:         $color-brand-secondary !default;
$color-radio-text:                      $color-black !default;
$color-radio-wrapper-border:            $color-white !default;
$color-radio-wrapper-background:        $color-white !default;
$color-radio-wrapper-active-background: $color-brand-secondary !default;
$color-radio-inline-border:             $color-radio-background !default;

// Checkout Summary
$color-delivery-method-background: #D9D7CC !default;

// Step Title
$color-step-title-sub-title: $color-black !default;

// Quick View
$color-quickview-close-button: $color-brand-secondary !default;

// Quick View Carousel
$transition-quickview-carousel-animation: linear ($base-transition / 2) !default;
$transition-quickview-carousel-base:      linear $base-transition !default;
$color-quickview-carousel-dot-active:     $color-brand-secondary !default;

// AKM2
$color-view-switcher-image-border: $color-brand-secondary !default;
$color-view-switcher-mobile-link:  $color-brand-secondary !default;
$color-view-switcher-desktop-link: $color-black !default;
$color-view-switcher-subtext:      $color-red !default;
$color-list-akm2-highlight:        #F3F9F9 !default;

// Minicart
$color-minicart-btn-link:            $color-ui-grey-2 !default;
$color-minicart-font:                $color-black;
$color-minicart-product-title:       $color-ui-black !default;
$color-minicart-product-title-hover: $color-brand-secondary !default;
$color-minicart-text:                #5F5F5E;

// Facet Tooltip
$color-tooltip-background:           $color-blue;
$color-tooltip-background-secondary: $color-brand-secondary;
$color-tooltip-font:                 $color-white;
$color-tooltip-before-border-top:    $color-blue;

// PDP
$color-special-price-label: $color-ui-black;

// Gallery
$color-fotorama-dot-bg-active: $color-ui-black;
$color-fotorama-dot-bg:        $color-off-white;

// Promo Form
$color-promo-code-btn-bg: $color-transparent !default;
$color-promo-code-font:   $color-ui-black !default;

// Dashboard
$color-dashboard-title-border-bottom:    $color-ui-grey-4;
$color-dashboard-navigation-active-link: $color-ui-black;
$color-back-to-orders-link-primary:      $color-ui-black;
$color-dashboard-link:                   $color-ui-grey-2;
$color-dashboard-link-hover:             $color-brand-secondary;
$color-dashboard-th:                     $color-black;
$color-order-detail-grand-total:         $color-black;
$color-toolbar-amount:                   $color-black;

// Facet/Filter
$color-active-filter-font:       $color-white;
$color-active-filter-background: $color-brand-secondary;
$color-remove-current-filter-bg: $color-alert-error;
$color-remove-current-filter:    $color-white;

// Saved Items
$color-remove-link:       $color-alert-error;
$color-remove-link-hover: lighten($color-alert-error, 10%);

// Header
$color-header-border: $color-ui-grey-4;

// Contact page
$color-contact-page-link:        $color-ui-black;
$color-contact-page-strong-font: $color-ui-black;
$color-store-border:             $color-mono-d;

// Shipping Grid
$shipping-grid-gutter:      20px;
$shipping-grid-gutter-half: $shipping-grid-gutter / 2;

// Progress Bar
$progress-icon-dimension: 30px;

// Flex grid
$flex-grid-column-count:         12;
$flex-grid-gutter:               15px;
$flex-grid-default-columns: (
    mobile:  3,
    tablet:  4,
    desktop: 5,
    wide:    6
);

// Pagination
$color-pagination-link:       $color-brand-secondary;
$color-pagination-link-hover: lighten($color-pagination-link, 5%);

// Basket
$color-basket-delete-link:       $color-ui-grey-2;
$color-basket-delete-link-hover: $color-brand-secondary;
$color-basket-view-saved:        $color-black;
$color-basket-view-saved-hover:  $color-brand-secondary;
$color-basket-border:            rgba(50, 50, 50, 0.1);

// Scrollbars
$color-scrollbar-bg:            rgba(0, 0, 0, 0.5);
$color-scrollbar-box-shadow:    rgba(255, 255, 255, 0.5);
