//------------------------------------
// #SLIDES
//------------------------------------

[data-content-type=slide] {
    box-sizing: border-box;
    min-height: inherit;
    overflow: hidden;

    > [data-element=link],
    > [data-element=empty_link] {
        color: inherit;
        min-height: inherit;
        text-decoration: inherit;

        &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }
}

.pagebuilder-slide-wrapper {
    border-radius: inherit;
    box-sizing: border-box;
    min-height: inherit;
    overflow-wrap: break-word;
    word-wrap: break-word;

    .pagebuilder-overlay {
        @include rem(padding, 30px);

        border-radius: inherit;
        box-sizing: border-box;
        transition: background-color 500ms ease;

        &.pagebuilder-poster-overlay {
            align-items: center;
            display: flex;
            justify-content: center;
            min-height: inherit;
        }

        &:not(.pagebuilder-poster-overlay) {
            @include rem(max-width, 540px);
        }
    }

    [data-element=content] {
        @include rem(min-height, 50px);
    }

    .pagebuilder-slide-button {
        @include rem(margin, 20px 0 0 0);

        max-width: 100%;
        text-align: inherit;
        transition: opacity 500ms ease;
        word-break: break-word;
    }
}

@include mq($until: tablet) {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
            max-width: none;
        }
    }
}

.pagebuilder-slide-wrapper {
    .pagebuilder-poster-content {
        width: 100%;
    }
}

[data-appearance=collage-centered] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

[data-appearance=collage-left] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-right: auto;
        }
    }
}

[data-appearance=collage-right] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
        }
    }
}
