//------------------------------------
// #MIXINS
//------------------------------------

// Global mixins available to the entire project. Define file-specific
// mixins inside the file to which they relate.

// Margins and positioning helper function
@function spacer($val: 1) {
    @return $spacer * $val + 0px;
}

// Mixin to create a declaration whose value is a rem unit. Also provide pixel
// fallback.
//
// .simple-usage {
//   @include rem(padding, 10px);
// }
@mixin rem($property, $value, $important: null) {
    @if $important {
        $important: !important;
    }

    #{$property}: $value $important;

    $rem-value-list: null;
    @each $item in $value {
        $should-rem: $item != 0 and type-of($item) != string and type-of($item) != color;

        @if $should-rem {
            $item: rem($item);
        }

        $rem-value-list: append($rem-value-list, $item, space);
    }

    #{$property}: $rem-value-list $important;
}

// Mixin to drop micro clearfix into a selector.
//
// .simple-usage {
//   @include clearfix();
// }
//
// .detailed-usage {
//   @include clearfix(important);
// }
@mixin clearfix($important: false) {
    // Determine whether or not to make declarations important.
    @if ($important == false) {
        $important: null;
    } @else {
        $important: !important;
    }

    &::after {
        content: "" $important;
        display: table $important;
        clear: both $important;
    }
}

// Generate `:hover` and `:focus` styles in one go. Optionally pass in classes
// (e.g. `.is-current`) to apply the same styles to.
//
// .simple-uage {
//   @include hocus() {
//     text-decoration: underline;
//   }
// }
//
// .detailed-uage {
//   @include hocus('.is-current') {
//     text-decoration: underline;
//   }
// }
@mixin hocus($selector: null) {
    @if ($selector != null) {
        $selector: '&' + $selector + ',';
    }

    #{$selector}
    &:hover,
    &:focus {
        @content;
    }
}

// Applies default browser styles to an element. Designed to be applied to a
// non-native element on hover, so it matches other elements like links and
// buttons on keyboard focus.
//
// .c-widget:focus {
//   @include add-default-focus-styles();
// }
//
@mixin add-default-focus-styles() {
    @content;
    outline-width: 5px;
    outline-style: solid;
    outline-color: Highlight;

    @media (-webkit-min-device-pixel-ratio: 0) {
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
    }
}

// Mixin to override all the link states
@mixin link-color($color-default: $color-body, $color-hover: $color-links, $important: false) {
    @if ($important == false) {
        $important: null;
    } @else {
        $important: !important;
    }

    color: $color-default $important;

    &:visited,
    &:active {
        color: $color-default $important;
    }

    @include hocus() {
        color: $color-hover $important;
    }
}

// Retina image mixin
@mixin image-2x($image, $width, $height, $size: null) {

    $background-size: $width $height;

    @if ($size != null) {
        $background-size: $size;
    }

    @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
        // On retina, use image that's scaled by 2
        background-image: url($image);
        background-size: $background-size;
    }
}

// Mixin to add placeholder text colour on inputs
// Source: https://css-tricks.com/snippets/css/style-placeholder-text/
//
// .simple-uage {
//   @include placeholder-color(#fff);
// }
//
@mixin placeholder-color($color) {
    &::-webkit-input-placeholder {
        color: $color;
    }

    &:-moz-placeholder { // Firefox 18-
        color: $color;
    }

    &::-moz-placeholder { // Firefox 19+
        color: $color;
        opacity: 1; // Firefox applies opacity to placeholders
    }

    &:-ms-input-placeholder { // IE 10+
        color: $color !important;
    }

    &::-ms-input-placeholder { // Edge
        color: $color;
    }

    &::placeholder { // Standard, Firefox 51+
        color: $color;
    }
}

// Mixin to calculate width based on given width & columns, allows widths to be
// calculated and used in rare cases without classes.
//
// See _trumps.widths.scss for preferred class based usage.
//
// .simple-usage {
//   @include width(2, 12);
// }
//
@mixin width($width, $columns) {
    // sass-lint:disable no-important
    width: ($width / $columns) * 100% !important;
}

// Mixin to force any container to take up the full width of the viewport based
// on the given breakpoint
//
// .example-usage {
//   @include mq($until: tablet) {
//     @include full-width(mobile);
//   }
// }
@mixin full-width($breakpoint) {
    width: 100vw;
    @if $breakpoint == mobile {
        margin-left: -5vw;
    } @else {
        margin-left: calc((100vw - #{map-get($mq-breakpoints, $breakpoint)}) / -2);
    }
}

// Visually hidden mixin for application to elements which should be hidden, yet accessible for screen readers
@mixin visually-hidden() {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

// This mixin provides a convenient way to add mobile gutter support to an element
@mixin mobile-gutter() {
    @include rem(padding-left, $mobile-full-width-gutter);
    @include rem(padding-right, $mobile-full-width-gutter);

    @include mq($from: tablet) {
        padding-left: 0;
        padding-right: 0;
    }
}

// This mixin provides to add basic style for displaying position of bubble on toolslips
@mixin tooltip-bubble {
    @include rem(border-left, $width-half-arrow solid transparent);
    @include rem(border-right, $width-half-arrow solid transparent);

    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 50%;
}
