//------------------------------------
// #DISPLAY EXTEND
//------------------------------------

// Additional flex box helpers
.u-flex-display {
    display: flex !important;
}

.u-flex-align-center {
    align-items: center;
}

.u-flex-grow {
    flex-grow: 1;
}

.u-flex-row {
    @extend .u-flex-display;
    flex-flow: row wrap;
}

.u-flex-column {
    @extend .u-flex-display;
    flex-flow: column;
}
