//------------------------------------
// #COLUMNS
//------------------------------------

.pagebuilder-column-group {
    flex-wrap: wrap;

    @include mq($from: tablet) {
        flex-wrap: nowrap;
    }
}

.pagebuilder-column {
    flex-basis: 100%;
    box-sizing: border-box;

    @include mq($from: tablet) {
        flex-basis: auto;
    }

    @include mq($until: tablet) {
        background-attachment: scroll !important;
    }
}
