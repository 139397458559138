//------------------------------------
// #LOADING MASK
//------------------------------------

.c-pagebuilder-loading-mask {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-page-builder-loading-mask-background;
}

.c-pagebuilder-loading-mask__spinner {
    @include rem(border, 6px solid $color-page-builder-loading-mask-spinner-track);
    @include rem(width, $page-builder-loading-mask-spinner-width);
    @include rem(height, $page-builder-loading-mask-spinner-height);

    border-top-color: $color-page-builder-loading-mask-spinner-highlight;
    border-radius: 50%;
    animation: page-builder-loading-mask-spin 1s linear infinite;
    left: calc(50% - #{rem($page-builder-loading-mask-spinner-width / 2)});
    top: calc(50% - #{rem($page-builder-loading-mask-spinner-height / 2)});
    position: absolute;
    transform: translate(-50%, -50%);
}

@keyframes page-builder-loading-mask-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
