//------------------------------------
// #PAGE BUILDER CONFIG
//------------------------------------

// Loading masks
$page-builder-loading-mask-spinner-width:           60px;
$page-builder-loading-mask-spinner-height:          60px;
$color-page-builder-loading-mask-spinner-track:     $color-ui-grey-5;
$color-page-builder-loading-mask-spinner-highlight: $color-brand-primary;
$color-page-builder-loading-mask-background:        $color-white;
