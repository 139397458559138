//------------------------------------
// #LAYOUTS
//------------------------------------

.product-full-width-section {
    padding: .5em 0;

    .block.review-add {
        margin-top: 2.7rem;
    }
}

.page-layout-product-full-width {
    .block.related {
        margin-top: 2.7rem;
    }
}

.page-main-details .product-section-title {
    @include rem(margin-bottom, 15px);
    @include rem(padding-bottom, 12px);

    border-bottom: 1px solid $color-ui-grey-3;
    border-bottom: 1px solid var(--color-ui-grey-3);
}

.additional-attributes-wrapper .additional-attributes {
    border: none;
    width: auto;

    > tbody > tr {
        > th {
            @include rem(padding, 5.5px 30px 10px 0);

            border: none;
        }
        > td {
            @include rem(padding, 5.5px 5px 10px);

            border: none;
        }
    }
}

.cms-index-index.page-layout-cms-full-width .nav-sections {
    margin-bottom: 0;
}
