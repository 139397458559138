//------------------------------------
// #SLIDERS
//------------------------------------

div[data-content-type=slider] {
    display: none;

    &.slick-initialized {
        @include rem(margin-bottom, 35px, true); // required due to inline styles added by Slick

        display: block;
    }

    .slick-list,
    .slick-track,
    .slick-slide {
        min-height: inherit;

        > div {
            min-height: inherit;
            overflow: hidden;
            width: 100%;
        }
    }
}
