//------------------------------------
// #PAGE
//------------------------------------

.cms-content-important {
    @include rem(font-size, 22px);
    @include rem(margin, 0 0 35px -20px);
    @include rem(padding, 20px);

    background-color: $color-white;
    background-color: var(--color-white);
    color: $color-ui-grey-1;
    color: var(--color-ui-grey-1);
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.1;
}

.pagebuilder-full-width {
    float: left;
    width: 100%;
}

.pagebuilder-content-type {
    @include rem(margin-bottom, 20px);

    box-sizing: border-box;
}

.pagebuilder-mobile-hidden {
    @extend .u-display-hide--from-mobile;
    @extend .u-display-inline--from-tablet;
}

.pagebuilder-mobile-only {
    @extend .u-display-hide--from-tablet;
}
