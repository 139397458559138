//------------------------------------
// #BANNERS
//------------------------------------

[data-content-type=banner] > [data-element=link],
[data-content-type=banner] > [data-element=empty_link] {
    color: inherit;
    text-decoration: inherit;

    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
}

.pagebuilder-banner-wrapper {
    background-clip: padding-box;
    border-radius: inherit;
    box-sizing: border-box;
    overflow-wrap: break-word;
    word-wrap: break-word;

    .pagebuilder-overlay {
        @include rem(padding, 30px);

        box-sizing: border-box;
        transition: background-color 500ms ease;

        &.pagebuilder-poster-overlay {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        &:not(.pagebuilder-poster-overlay) {
            @include rem(max-width, 540px);
        }
    }

    [data-element=content] {
        @include rem(min-height, 50px);
    }

    .pagebuilder-banner-button {
        @include rem(margin, 20px 0 0 0);

        max-width: 100%;
        text-align: inherit;
        transition: opacity 500ms ease;
        word-break: break-word;
    }
}

@include mq($until: tablet) {
    .pagebuilder-banner-wrapper {
        background-attachment: scroll !important;

        .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
            max-width: none;
        }
    }
}

.pagebuilder-banner-wrapper {
    .pagebuilder-poster-content {
        width: 100%;
    }
}

[data-appearance=collage-centered] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

[data-appearance=collage-left] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-right: auto;
        }
    }
}

[data-appearance=collage-right] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
        }
    }
}
