//------------------------------------
// #DISPLAY
//------------------------------------

@each $alias, $value in $mq-breakpoints {
    @include mq($from: $value) {
        .u-display-hide\@#{$alias},
        .u-display-hide--from-#{$alias} {
            display: none !important;
        }

        .u-display-block\@#{$alias},
        .u-display-block--from-#{$alias} {
            display: block !important;
        }

        .u-display-initial\@#{$alias},
        .u-display-initial--from-#{$alias} {
            display: initial !important;
        }

        .u-display-inline\@#{$alias},
        .u-display-inline--from-#{$alias} {
            display: inline !important;
        }

        .u-display-inline-block\@#{$alias},
        .u-display-inline-block--from-#{$alias} {
            display: inline-block !important;
        }

        // Flex box helpers
        .u-flex-display\@#{$alias},
        .u-flex-display--from-#{$alias} {
            display: flex !important;
        }

        .u-flex-grow\@#{$alias},
        .u-flex-grow--from-#{$alias} {
            flex-grow: 1;
        }

        .u-flex-row\@#{$alias},
        .u-flex-row--from-#{$alias} {
            display: flex;
            flex-flow: row wrap;
        }

        .u-display-flex\@#{$alias},
        .u-display-flex--from-#{$alias} {
            display: flex !important;
        }
    }
}

.u-display-hide {
    display: none !important;
}

.u-display-block {
    display: block !important;
}

.u-display-initial {
    display: initial !important;
}

.u-display-inline {
    display: inline !important;
}

.u-display-inline-block {
    display: inline-block !important;
}

.u-display-flex {
    display: flex !important;
}
