// CSS Properties

:root {
    --color-blue:   #{$color-blue};
    --color-yellow: #{$color-yellow};
    --color-red:    #{$color-red};
    --color-peach:  #{$color-peach};
    --color-green:  #{$color-green};
    --color-orange: #{$color-orange};
    --color-black:  #{$color-black};
    --color-white:  #{$color-white};

    // UI
    --color-ui-black:   #{$color-ui-black};
    --color-ui-grey-1:  #{$color-ui-grey-1};
    --color-ui-grey-2:  #{$color-ui-grey-2};
    --color-ui-grey-3:  #{$color-ui-grey-3};
    --color-ui-grey-4:  #{$color-ui-grey-4};
    --color-ui-grey-5:  #{$color-ui-grey-5};

    // Brand
    --color-brand-primary:      #{$color-brand-primary};
    --color-brand-secondary:    #{$color-brand-secondary};
    --color-brand-tertiary:     #{$color-brand-tertiary};

    // Alert colours
    --color-alert-text:         #{$color-alert-text};
    --color-alert-error:        #{$color-alert-error};
    --color-alert-error-text:   #{$color-alert-error-text};
    --color-alert-success:      #{$color-alert-success};
    --color-alert-success-text: #{$color-alert-success-text};
    --color-alert-warning:      #{$color-alert-warning};
    --color-alert-warning-text: #{$color-alert-warning-text};
    --color-alert-notice:       #{$color-alert-notice};
    --color-alert-notice-text:  #{$color-alert-notice-text};

    // Global
    --color-font:   #{$color-font};
    --color-body:   #{$color-body};
    --color-border: #{$color-border};

    // Links
    --color-links:         #{$color-links};
    --color-links-active:  #{$color-links-active};
    --color-links-visited: #{$color-links-visited};

    // Header
    --color-default-header: #{$color-default-header};
    --color-header-links:   #{$color-header-links};

    // Footer
    --color-footer-bg:                    #{$color-footer-bg};
    --color-footer-copyright-bg:          #{$color-footer-copyright-bg};
    --color-footer-copyright-bg-mobile:   #{$color-footer-copyright-bg-mobile};
    --color-footer-copyright-text:        #{$color-footer-copyright-text};
    --color-footer-copyright-text-mobile: #{$color-footer-copyright-text-mobile};
    --color-footer-headers:               #{$color-footer-headers};
    --color-footer-list-header:           #{$color-footer-list-header};
    --color-footer-list:                  #{$color-footer-list};

    // Nav
    --color-nav-hover:           #{darken($color-nav-hover, 20%)};
    --color-category-item:       #{$color-category-item};
    --color-category-item-hover: #{$color-category-item-hover};
    --color-main-nav:            #{$color-main-nav};
    --color-main-nav-bg:         #{$color-main-nav-bg};

    // Forms
    --color-validation-error:       #{$color-validation-error};
    --color-checkbox-bg:            #{$color-checkbox-bg};
    --color-checkbox-border:        #{$color-checkbox-border};
    --color-checkbox-checked-bg:    #{$color-checkbox-checked-bg};
    --color-input-border:           #{$color-input-border};
    --color-input-border-focus:     #{$color-input-border-focus};
    --color-input-placeholder:      #{$color-input-placeholder};
    --color-input-focus:            #{$color-input-focus};
    --color-input-validation-valid: #{$color-input-validation-valid};
    --color-input-validation-error: #{$color-input-validation-error};
    --color-input-disabled:         #{$color-input-disabled};
    --color-input:                  #{$color-input};
    --color-input-bg:               #{$color-input-bg};
    --color-label:                  #{$color-label};

    // Buttons
    // Primary
    --color-btn:          #{$color-btn};
    --color-btn-bg:       #{$color-btn-bg};
    --color-btn-hover:    #{$color-btn-hover};
    --color-btn-bg-hover: #{darken($color-btn-bg-hover, 10%)};

    // Secondary
    --color-btn-secondary:          #{$color-btn-secondary};
    --color-btn-secondary-bg:       #{$color-btn-secondary-bg};
    --color-btn-secondary-hover:    #{$color-btn-secondary-hover};
    --color-btn-secondary-bg-hover: #{darken($color-btn-secondary-bg-hover, 10%)};

    // Plain
    --color-btn-plain:       #{$color-btn-plain};
    --color-btn-plain-hover: #{$color-btn-plain-hover};

    // Outline
    --color-btn-outline:              #{$color-btn-outline};
    --color-btn-outline-border:       #{$color-btn-outline-border};
    --color-btn-outline-hover:        #{$color-btn-outline-hover};
    --color-btn-outline-border-hover: #{$color-btn-outline-border-hover};

    // Disabled
    --color-btn-disabled:        #{$color-btn-disabled};
    --color-btn-disabled-bg:     #{$color-btn-disabled-bg};
    --color-btn-disabled-border: #{$color-btn-disabled-border};

    // Tabs
    --color-tab:          #{$color-tab};
    --color-tab-selected: #{$color-tab-selected};

    // Ratings
    --color-star-selected:   #{$color-star-selected};
    --color-star-unselected: #{$color-star-unselected};

    // Price
    --color-price-special: #{$color-price-special};

    // Gallery
    --color-gallery-thumbnail-border: #{$color-gallery-thumbnail-border};
    --color-gallery-dot:              #{$color-gallery-dot};

    // Breadcrumbs
    --color-breadcrumb-link:           #{$color-breadcrumb-link};
    --color-breadcrumb-link-hover:     #{$color-breadcrumb-link-hover};
    --color-breadcrumb-item:           #{$color-breadcrumb-item};
    --color-breadcrumb-item-separator: #{$color-breadcrumb-item-separator};

    // React PLP
    --color-plp-sidebar-mob-bg:        #{$color-plp-sidebar-mob-bg};
    --color-plp-sidebar-mob-plus-icon: #{$color-plp-sidebar-mob-plus-icon};
    --color-plp-sidebar-actions:       #{$color-plp-sidebar-actions};
    --color-plp-loader-bg:             #{$color-plp-loader-bg};
    --color-plp-toolbar-bg:            #{$color-plp-toolbar-bg};

    // React PLP attributes
    --color-attribute-after-image-bg:   #{$color-attribute-after-image-bg};
    --color-attribute-after-image-text: #{$color-attribute-after-image-text};

    // React search suggestions
    --color-suggestions-input-border:  #{$color-suggestions-input-border};
    --color-suggestions-input-bg:      #{$color-suggestions-input-bg};
    --color-suggestions-hover-bg:      #{$color-suggestions-hover-bg};
    --color-suggestions-hover-text:    #{$color-suggestions-hover-text};

    // Tooltips
    --color-tooltips-hover-bg:     #{$color-tooltips-hover-bg};
    --color-tooltips-hover-text:   #{$color-tooltips-hover-text};
    --color-tooltips-hover-border: #{$color-tooltips-hover-border};
}
