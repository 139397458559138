//------------------------------------
// #ROWS
//------------------------------------

[data-content-type=row][data-appearance=contained] {
    box-sizing: border-box;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: map-get($mq-breakpoints, wide);

    [data-element=inner] {
        box-sizing: border-box;
    }
}

[data-content-type=row][data-appearance=full-bleed] {
    box-sizing: border-box;
}

[data-content-type=row][data-appearance=full-width] {
    box-sizing: border-box;

    > .row-full-width-inner {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: map-get($mq-breakpoints, wide);
        width: 100%;
    }
}

@include mq($until: tablet) {
    [data-content-type=row][data-appearance=row] {
        [data-element=inner] {
            background-attachment: scroll !important;
        }
    }

    [data-content-type=row][data-appearance=full-bleed],
    [data-content-type='row'][data-appearance=full-width] {
        background-attachment: scroll !important;
    }
}
